<template>
  <div>
    <el-row type="flex">
    <div class="search-bar">
      <el-col :span="16">
      <el-input
        placeholder="请输入搜索内容"
        v-model="searchQuery"
        clearable
        style="width: 100%"
      >
      </el-input>
    </el-col>
    <el-col :span="4">
      <el-button type="success" round @click="resetEmpty"
        ><i class="el-icon-refresh-right"></i>重置</el-button
      ></el-col>
      <el-col :span="4" :offset="1">
      <el-button type="primary" round @click="fetchData"
        ><i class="el-icon-search"></i>搜索</el-button
      >
    </el-col>
    </div>
  </el-row>
  <el-row type="flex">
    <el-table
      :data="tableData"
      border
      stripe
      style="width: 100%; height: 100%"
      v-loading="loading"
      :header-cell-style="{
        background: '#eef1f6',
        color: '#606266',
        'text-align': 'center',
      }"
    >
      <el-table-column
        type="index"
        label="序号"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="item_basic_info.shop_title"
        label="店铺名称"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="item_basic_info.short_title"
        label="商品名称"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="sendDate"
        label="图片"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.item_basic_info.pict_url"
            :preview-src-list="scope.row.item_basic_info.small_images.string"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="详情" header-align="center" align="center">
        <template slot-scope="scope">
          <el-link
            :underline="false"
            :href="getFullUrl(scope.row.publish_info.coupon_share_url)"
            target="_blank"
            >领卷购买<i class="el-icon-sold-out"></i>
          </el-link>
        </template>
      </el-table-column>
      <el-table-column label="详情" header-align="center" align="center">
        <template slot-scope="scope">
        <el-link
          :underline="false"
           :href="getFullUrl(scope.row.publish_info.click_url)"
           target="_blank"
          >土豪直达<i class="el-icon-shopping-cart-full"></i>
        </el-link>
      </template>
      </el-table-column>
    </el-table>
  </el-row>
  </div>
</template>

<script>
export default {
  name: "MsgSelect",
  data() {
    return {
      data: null, // 保存获取到的数据
      error: null, // 保存错误信息
      tableData: [],
      datetimerange: null,
      total: null,
      size: null,
      current: null,
      pages: null,
      loading: false,
      searchQuery: "",
    };
  },
  mounted() {},
  methods: {
    getFullUrl(endpoint) {
      const baseUrl = "https:";
      return `${baseUrl}${endpoint}`; // 拼接完整的 URL
    },
    fetchData() {
      this.$axios
        .get("/search/" + encodeURIComponent(this.searchQuery))
        .then((res) => {
          console.log(
            res.tbk_dg_material_optional_upgrade_response.result_list.map_data
          );
          // 根据需要处理数据
          this.tableData =
            res.tbk_dg_material_optional_upgrade_response.result_list.map_data; // 确保 tableData 有一个默认值
          this.loading = false;
        })
        .catch((error) => {
          // 打印错误信息到控制台
          console.error("Error occurred:", error.message);
          this.error = error.message; // 保存错误信息
          this.loading = false;
        });
    },
    resetEmpty() {
      this.searchQuery = "";
      this.$message({
        showClose: true,
        message: "重置成功",
        type: "success",
      });
    },

  },
};
</script>
<style scoped>
.search-bar {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  /* 控制输入框之间的间距 */
}

.search-bar .el-input,
.search-bar .el-date-picker,
.search-bar .el-button {
  margin-right: 10px;
  /* 设置组件之间的右边距 */
}

.search-bar .el-button:last-child {
  margin-right: 0;
  /* 最后一个按钮不需要右边距 */
}
</style>