// src/router/index.js
import Vue from "vue";
import Router from "vue-router";
import Index from "@/components/HelloWorld.vue"


Vue.use(Router);

//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 定义路由配置
let router = new Router({
  routes: [
    {
      path: "/",
      name: "Index",
      component: Index,
    },
  ],
  mode: "history", //设置路由模式，history 不带#
});

// 导出
export default router;
